<template>
  <div>
    <b-modal
      v-model="isModal"
      id="modal-table"
      size="lg"
      body-class="p-0"
      hide-header
      hide-footer
    >
      <div class="modal-table">
        <b-row>
          <b-col cols="6">
            <strong>Promocode SerialNo</strong>
            <b-form-input
              v-model="formSearch.promocodeSerialNo"
              class="mt-2"
              placeholder="Promocode SerialNo"
              type="text"
              step="any"
            ></b-form-input>
          </b-col>
          <b-col cols="6">
            <InputSelect
              v-model="formSearch.isUseStatus"
              :options="optionStatusPromocode"
              title="Status"
              valueField="value"
              textField="text"
            />
          </b-col>
        </b-row>
        <div class="header">
          <b-row class="justify-content-end mr-1">
            <b-button class="btn-filter mr-2" @click="onClickHandleSearch">
              Search
            </b-button>
            <b-button
              class="btn-filter"
              @click="exportPromocodeGeneratedDetail"
            >
              Export
            </b-button>
          </b-row>
        </div>
        <div class="body">
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :pageOptions="pageOptions"
            :rows="rows"
            :filter="filter"
            @pagination="pagination"
          />
        </div>
        <div class="footer-modal">
          <b-button class="btn-filter" @click="closeModal"> Close </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isModal: false,

      //state formSearch
      formSearch: {
        promocodeSerialNo: null,
        isUseStatus: null,
      },

      //optionStatus Promocode
      optionStatusPromocode: [
        {
          value: null,
          text: "ทั้งหมด",
        },
        {
          value: false,
          text: "ยังไม่ถูกใช้งาน",
        },
        {
          value: true,
          text: "ใช้งานแล้ว",
        },
      ],

      // state table
      filter: {
        skip: 1,
        page: 1,
        take: 10,
      },
      items: [],
      fields: [
        {
          key: "promocodeSerialNo",
          label: "Promocode SerialNo",
          class: "w-100px text-nowrap",
        },
        {
          key: "isUseStatusString",
          label: "Status",
          class: "w-100px text-nowrap",
        },

        // { key: "actions", label: "Action" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  watch: {
    isModal(val) {
      if (val) {
        this.getPromocodeGeneratedDetail();
      }
    },
  },
  methods: {
    async onClickHandleSearch() {
      await this.getPromocodeGeneratedDetail();
    },
    resolveAndDownloadBlob(v, filename) {
      return this.$services.utils.resolveAndDownloadBlob(v, filename);
    },
    async getPromocodeGeneratedDetail() {
      const obj = {
        promocodeSerialNo: this.formSearch.promocodeSerialNo,
        isUseStatus: this.formSearch.isUseStatus,
        skip: this.filter.skip,
        take: this.filter.take,
      };
      const getData =
        await this.$services.promocode.getPromocodeGeneratedDetail(
          this.$route.params.id,
          obj
        );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },
    async exportPromocodeGeneratedDetail() {
      const obj = {
        promocodeSerialNo: this.formSearch.promocodeSerialNo,
        isUseStatus: this.formSearch.isUseStatus,
      };
      const getData =
        await this.$services.promocode.exportPromocodeGeneratedDetail(
          this.$route.params.id,
          obj
        );

      this.resolveAndDownloadBlob(getData, `PromocodeExport`);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.skip = 1;
      this.filter.take = value;
      this.getPromocodeGeneratedDetail();
    },
    pagination(page) {
      this.filter.skip = page;
      this.filter.page = page;
      this.getPromocodeGeneratedDetail();
    },
    clearForm() {
      this.formSearch = {
        promocodeSerialNo: null,
        isUseStatus: null,
      };
    },
    closeModal() {
      this.clearForm();
      this.$bvModal.hide("modal-table");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-table {
  padding: 15px;
  .header {
    text-align: right;
    margin: 10px 0px;

    .btn-export {
      width: 100px;
      background: white;
      color: black;
    }
  }
  .body {
  }

  .footer-modal {
    text-align: right;
    margin-top: 10px;
  }
}

// ! OVERRIDE
.btn-filter {
  width: 100px;
}
</style>
