<template>
  <div>
    <b-modal
      v-model="isModal"
      id="modal-basic-condition"
      body-class="p-0"
      hide-header
      hide-footer
      size="lg"
    >
      <div class="modal-basic-condition">
        <b-row>
          <b-col cols="6">
            <InputSelect
              v-model="data.conditionType"
              :options="options"
              title="รูปแบบเงื่อนไข"
              valueField="id"
              textField="name"
              :isValidate="$v.data.conditionType.$error"
              :v="$v.data.conditionType"
              :disabled="data.id > 0"
              @onDataChange="onDataChange"
            />
          </b-col>
          <b-col cols="6">
            <InputText
              v-model="data.conditionValue"
              textFloat="Value"
              placeholder="0"
              type="number"
              name="value"
              isRequired
              :isValidate="$v.data.conditionValue.$error"
              :v="$v.data.conditionValue"
            />
          </b-col>
        </b-row>
        <b-row v-if="data.conditionType == 2 || data.conditionType == 4">
          <b-col class="mb-2" cols="12">
            <div class="header-table">
              <div class="w-100">
                <!-- <AutoComplete
                  ref="autocomplete"
                  v-model="selectProductId"
                  id="eventId"
                  label="Product"
                  placeholder="Product"
                  dataListID="productName"
                  name="productName"
                  valueField="productId"
                  showValue=""
                  :optionsList="optionsEvent"
                  @input="handleInput"
                /> -->
                <AutocompleteCustom
                  v-model="selectProductId"
                  ref="autocomplete"
                  id="eventId"
                  label="Product"
                  placeholder="Product"
                  dataListID="productName"
                  name="productName"
                  valueField="productId"
                  showValue=""
                  :optionsList="optionsEvent"
                  @handleInput="handleInput"
                  absolute
                />
              </div>
              <b-button class="btn-filter add" @click="addProduct">
                Add
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <TableConditionModal
              :items="data.promocodeProductGroup.listItem"
              :fields="isLoopCondition ? fieldsTrue : fieldsFalse"
              :isBusy="isBusy"
              :rows="rows"
              :filter="filter"
              :pageOptions="pageOptions"
              @removeItem="removeItem"
              :v="$v"
            />
            <p
              class="m-0 mb-2 text-error"
              v-if="$v.data.promocodeProductGroup.listItem.$error"
            >
              Please select product
            </p>
          </b-col>
        </b-row>
        <div class="footer-modal">
          <b-button class="btn-filter" @click="closeModal">Cancel</b-button>
          <div class="mx-2"></div>
          <b-button class="btn-filter gold" @click="addAllProduct">
            Add
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
import TableConditionModal from "../table/TableConditionModal.vue";
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";

export default {
  components: {
    TableConditionModal,
    AutocompleteCustom,
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    eventId: {
      type: Number,
      default: 0,
    },
    eventIdNew: {
      type: Number,
      default: 0,
    },
    idNew: {
      type: Number,
      default: 0,
    },
    isLoopCondition: {
      type: Boolean,
      default: false,
    },
    editDataCondition: {
      type: Object,
      default: {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModal: false,

      data: {
        id: 0,
        conditionType: null,
        conditionName: "",
        conditionTypeName: "",
        conditionValue: null,
        conditionProductGroupId: 0,
        isApplyConditionUsed: false,
        isLoopCondition: false,
        promocodeId: 0,
        isDelete: 0,
        promocodeProductGroup: {
          id: 0,
          productType: "PRODUCT",
          operation: "IN",
          orderCondition: 1,
          listItem: [],
        },
      },

      optionsEvent: [],
      form: {
        eventId: 0,
        keyword: "",
      },

      selectProductId: null,
      selectProduct: null,

      //   state table condition
      items: [],
      rows: 0,
      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      fieldsFalse: [
        {
          key: "productId",
          label: "ID",
          class: "w-50px text-nowrap",
        },
        {
          key: "productName",
          label: "Product Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "eventName",
          label: "Event Name",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      fieldsTrue: [
        {
          key: "productId",
          label: "ID",
          class: "w-50px text-nowrap",
        },
        {
          key: "productName",
          label: "Product Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "eventName",
          label: "Event Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusy: false,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // delay
      isDelay: null,
    };
  },
  validations() {
    return {
      data: {
        conditionType: {
          required,
        },
        conditionValue: {
          required,
          minValue: minValue(1),
        },
        promocodeProductGroup: {
          listItem: {
            required: requiredIf(() => {
              return (
                this.data.conditionType == 2 || this.data.conditionType == 4
              );
            }),
            $each: {
              quantity: {
                required,
                minValue: minValue(1),
              },
            },
          },
        },
      },
    };
  },

  watch: {
    isModal(val) {
      if (!val) {
        this.clearData();
      } else {
        this.form = {
          eventId: this.eventId == 1 ? this.eventIdNew : 0,
          keyword: "",
        };
        this.getOptionProductPromocodeDetail();
      }
    },
  },

  methods: {
    async getOptionProductPromocodeDetail() {
      const getData =
        await this.$services.master.getOptionProductPromocodeDetail(
          this.$route.params.id,
          this.form
        );

      if (getData.result == 1) {
        this.optionsEvent = getData.detail.map((x) => {
          x.name = `${x.eventName} - ${x.productName}`;

          return x;
        });
      }
    },
    addProduct() {
      const getValue = this.$refs.autocomplete.selectData;

      if (!getValue) {
        return;
      }

      let find = this.optionsEvent.find(
        (x) => x.productId == getValue.productId
      );
      find.order = 1;
      this.selectProduct = find;

      let checkDupicate = this.data.promocodeProductGroup.listItem.some(
        (x) => x.productId == this.selectProduct.productId
      );

      // default quantity
      this.selectProduct.quantity = 1;

      if (this.selectProduct && !checkDupicate) {
        this.data.promocodeProductGroup.listItem.push(this.selectProduct);
        this.selectProduct = null;
      }

      this.$refs.autocomplete.clearInput();

      this.form.keyword = "";
      this.getOptionProductPromocodeDetail();
    },
    handleInput(val) {
      const isCondition = Number(val) ? true : false;

      if (isCondition) {
        return;
        // let find = this.optionsEvent.find((x) => x.productId == val);
        // find.order = 1;
        // this.selectProduct = find;
      }

      if (this.isDelay) {
        clearTimeout(this.isDelay);
      }

      this.isDelay = setTimeout(() => {
        this.form = {
          eventId: this.eventId == 1 ? this.eventIdNew : 0,
          keyword: val,
        };
        this.getOptionProductPromocodeDetail();
      }, 500);
    },
    removeItem(obj) {
      this.data.promocodeProductGroup.listItem =
        this.data.promocodeProductGroup.listItem.filter((x) => {
          return x.productId != obj.productId;
        });
    },
    addAllProduct() {
      this.$v.data.$touch();

      if (this.$v.data.$error) {
        return;
      }

      let finalText = [];

      this.data.promocodeProductGroup.listItem.forEach((x) => {
        finalText.push(x.productName);
      });

      finalText = finalText.join(" , ");

      this.data.conditionName = finalText;

      //  add new data

      if (this.data.id == 0) {
        this.data.id = `new${this.idNew}`;
      }

      // type isLoopCondition
      this.data.isLoopCondition = this.isLoopCondition;

      this.$emit("addAllProduct", this.data);

      this.closeModal();
    },
    onDataChange(val) {
      const find = this.options.find((x) => x.id == val);
      this.data.conditionTypeName = find.name;
    },
    closeModal() {
      this.clearData();
      this.$bvModal.hide("modal-basic-condition");
    },
    convertDataEdit() {
      this.data = this.editDataCondition;
    },
    clearData() {
      this.data = {
        id: 0,
        conditionType: null,
        conditionName: "",
        conditionTypeName: "",
        conditionValue: null,
        conditionProductGroupId: 0,
        isApplyConditionUsed: false,
        isLoopCondition: false,
        promocodeId: 0,
        isDelete: 0,
        promocodeProductGroup: {
          id: 0,
          productType: "PRODUCT",
          operation: "IN",
          orderCondition: 1,
          listItem: [],
        },
      };

      this.$v.data.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-basic-condition {
  padding: 20px;

  .header-table {
    display: flex;
    align-items: flex-end;
  }

  .footer-modal {
    display: flex;
  }
}

// ! override
.btn-filter {
  width: 100%;

  &.add {
    max-width: 100px;
    width: 100%;
  }

  &.gold {
    background: var(--secondary-color);
    color: white !important;
    border-color: transparent !important;
  }
}
</style>
