import { render, staticRenderFns } from "./ModalTable.vue?vue&type=template&id=bc43b6aa&scoped=true&"
import script from "./ModalTable.vue?vue&type=script&lang=js&"
export * from "./ModalTable.vue?vue&type=script&lang=js&"
import style0 from "./ModalTable.vue?vue&type=style&index=0&id=bc43b6aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc43b6aa",
  null
  
)

export default component.exports