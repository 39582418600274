<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(id)="{ item }">
            <p class="m-0">
              {{ handleId(item.id) }}
            </p>
          </template>

          <template #cell(conditionName)="{ item }">
            <p class="m-0">
              {{ item.conditionName || "-" }}
            </p>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <font-awesome-icon
              class="mr-4 pointer"
              icon="pen"
              @click="editItemDiscount(item)"
            />
            <font-awesome-icon
              class="pointer"
              icon="trash-alt"
              @click="removeItemDiscount(item)"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    previewOrPublish: {
      type: Number,
      require: true,
    },
  },
  methods: {
    editItemDiscount(obj) {
      this.$emit("editItemDiscount", obj);
    },
    handleId(id) {
      let text = "";

      id = id.toString();

      if (id.includes("new")) {
        text = "N";
      } else {
        text = id;
      }

      return text;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },

    removeItemDiscount(obj) {
      this.$emit("removeItemDiscount", obj);
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
