<template>
  <div class="promocode-detail">
    <h5 class="text-type">Promocode Type : {{ selectType.name }}</h5>
    <div class="body" v-if="optionAll">
      <div class="detail">
        <b-row>
          <b-col cols="12">
            <InputText
              v-model="data.promocode.name"
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="promocodeName"
              isRequired
              :isValidate="$v.data.promocode.name.$error"
              :v="$v.data.promocode.name"
            />
          </b-col>

          <b-col cols="12">
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="data.promocode.eventId"
                :options="optionEventId"
                :aria-describedby="ariaDescribedby"
                name="radios-promocode"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="data.promocode.eventId == 1">
            <InputSelect
              v-model="eventIdNew"
              :options="optionAll.event"
              id="newEvent"
              title="เลือก Event"
              valueField="id"
              textField="name"
              isRequired
              :isValidate="$v.eventIdNew.$error"
              @onDataChange="changeEventIdNew"
              :disabled="isEdit && eventIdNew != null"
            />
            <p
              class="m-0 text-error"
              v-if="
                this.$v.eventIdNew.$error ||
                (errorCondition && eventIdNew == null)
              "
            >
              กรุณาเลือก Event
            </p>
          </b-col>
          <b-col cols="12">
            <div class="d-flex align-items-center">
              <b-form-group label="Code Type" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="data.promocode.serialGenarate"
                  :options="optionCodeType"
                  :aria-describedby="ariaDescribedby"
                  name="radios-codetype"
                ></b-form-radio-group>
              </b-form-group>
              <b-button
                class="btn-filter modal-table"
                @click="opemModalTable"
                v-if="isEdit && data.promocode.serialGenarate"
              >
                Check Code
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <div
              class="d-flex w-100"
              v-if="data.promocode.serialGenarate == false"
            >
              <div class="w-100">
                <InputText
                  v-model="data.promocode.prefix"
                  textFloat="Code"
                  placeholder="Code"
                  type="text"
                  name="promocodePrefix"
                  isRequired
                  :isValidate="$v.data.promocode.prefix.$error"
                  :v="$v.data.promocode.prefix"
                  :disabled="isEdit"
                />
              </div>
              <div class="mx-3"></div>
              <div class="w-100">
                <InputText
                  v-model="data.promocode.quantity"
                  textFloat="Quatity"
                  placeholder="0"
                  type="number"
                  name="promocodeQuantity"
                  isRequired
                  :isValidate="$v.data.promocode.quantity.$error"
                  :v="$v.data.promocode.quantity"
                />
                <p class="m-0 text-error" v-if="errorQuantity">
                  The value must greater than {{ cloneQuantity - 1 }}
                </p>
              </div>
            </div>
            <div
              class="d-flex w-100"
              v-if="data.promocode.serialGenarate == true"
            >
              <div class="w-100">
                <InputText
                  v-model="data.promocode.prefix"
                  textFloat="Prefix"
                  placeholder="Prefix"
                  type="text"
                  name="promocodePrefix2"
                  isRequired
                  :isValidate="$v.data.promocode.prefix.$error"
                  :v="$v.data.promocode.prefix"
                />
              </div>
              <div class="mx-3"></div>
              <div class="w-100">
                <InputText
                  v-model="data.promocode.quantity"
                  textFloat="Quatity"
                  placeholder="0"
                  type="number"
                  name="promocodeQuantity2"
                  isRequired
                  :isValidate="$v.data.promocode.quantity.$error"
                  :v="$v.data.promocode.quantity"
                />
                <p class="m-0 text-error" v-if="errorQuantity">
                  The value must greater than {{ cloneQuantity - 1 }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="font-weight-bold mb-2 text-black">
              Promocode Start time
              <!-- <span class="text-error">*</span> -->
            </div>
            <div class="input-container mb-0">
              <!-- :class="$v.data.promocode.startDate.$error && 'error'" -->
              <datetime
                v-model="data.promocode.startDate"
                type="datetime"
                format="dd/MM/yyyy HH:mm"
                value-zone="local"
                placeholder="Please Select Date"
                ref="promocodeStartDate"
              >
                <!-- :min-datetime="minDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.promocodeStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>

            <!-- <p
              class="mb-2 text-error"
              v-if="$v.data.promocode.startDate.$error"
            >
              Please select date
            </p> -->
          </b-col>
          <b-col cols="6">
            <div class="font-weight-bold mb-2 text-black">
              Promocode End time
              <!-- <span class="text-error">*</span> -->
            </div>
            <div class="input-container mb-0">
              <!-- :class="$v.data.promocode.endDate.$error && 'error'" -->
              <datetime
                v-model="data.promocode.endDate"
                type="datetime"
                format="dd/MM/yyyy HH:mm"
                value-zone="local"
                placeholder="Please Select Date"
                ref="promocodeEndDate"
              >
                <!-- :min-datetime="minDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.promocodeEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <!-- <p class="mb-2 text-error" v-if="$v.data.promocode.endDate.$error">
              Please select date
            </p> -->
          <b-col cols="6">
            <div class="font-weight-bold mb-2 text-black">
              Effective Booking Start Date
            </div>
            <div class="input-container">
              <datetime
                v-model="data.promocode.effectiveStartDate"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="effectiveStartDate"
              >
                <!-- :min-datetime="minDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.effectiveStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="font-weight-bold mb-2 text-black">
              Effective Booking End Date
            </div>
            <div class="input-container">
              <datetime
                v-model="data.promocode.effectiveEndDate"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="effectiveEndDate"
              >
                <!-- :min-datetime="minDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.effectiveEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="mt-3 mb-3">
              <b-form-checkbox
                v-model="data.promocode.isActive"
                switch
                :value="1"
                :unchecked-value="0"
              >
                Active
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="header-box">
        <p class="m-0">Condition</p>
      </div>

      <div class="detail">
        <b-row>
          <b-col cols="12">
            <b-form-group label="เงื่อนไขพื้นฐาน" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="data.promocode.isUseBasicCondition"
                :options="optionsCondition"
                :aria-describedby="ariaDescribedby"
                name="radios-condition"
                @change="clearDataCondition(data.promocode.isUseBasicCondition)"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="data.promocode.isUseBasicCondition == true">
            <div class="header-table">
              <strong class="m-0">Basic Condition</strong>
              <b-button class="btn-filter" @click="openModalCondition(false)">
                Add
              </b-button>
            </div>

            <TableCondition
              v-if="data.promocode.isUseBasicCondition == true"
              :items="arrConditionFalse"
              :fields="fieldsArrFalse"
              :isBusy="isBusyProduct"
              :rows="rowsProduct"
              :filter="filterProduct"
              :pageOptions="pageOptionsProduct"
              @editItem="editItem($event, false)"
              @removeItem="removeItem"
            />

            <p class="m-0 text-error" v-if="$v.data.promocodeCondition.$error">
              Please select product
            </p>
          </b-col>
          <b-col cols="12" v-if="data.promocode.isUseBasicCondition == true">
            <b-form-group label="Match Type" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="data.promocode.isApplyAllBasicCondition"
                :options="optionsMatchType"
                :aria-describedby="ariaDescribedby"
                name="radios-matchtype"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectType.id == 3 || selectType.id == 4">
        <div class="header-box">
          <p class="m-0">Loop condition</p>
        </div>

        <div class="detail">
          <div class="header-table">
            <strong class="m-0">เงื่อนไขการวนซ้ำ ( x )</strong>

            <b-button class="btn-filter" @click="openModalCondition(true)">
              Add
            </b-button>
          </div>
          <TableCondition
            :items="arrConditionTrue"
            :fields="fieldsArrTrue"
            :isBusy="isBusyProduct"
            :rows="rowsProduct"
            :filter="filterProduct"
            :pageOptions="pageOptionsProduct"
            @removeItem="removeItem"
            @editItem="editItem($event, true)"
          />

          <p
            id="special-error"
            class="m-0 text-error"
            v-if="
              data.promocode.isApplyAllBasicCondition == false &&
              arrConditionTrue.length == 0 &&
              IsValidate
            "
          >
            Please select product
          </p>
        </div>
      </div>

      <div class="header-box">
        <p class="m-0">Discount / Free product</p>
      </div>
      <div class="detail">
        <div v-if="selectType.id == 1">
          <b-row>
            <b-col cols="6">
              <strong>
                Discount
                <span class="text-error">*</span>
              </strong>

              <b-form-input
                :class="
                  $v.data.promocodeDiscount.$each[0].discountValue.$error &&
                  'error'
                "
                v-model="data.promocodeDiscount[0].discountValue"
                class="mt-2"
                placeholder="0.00"
                type="number"
                step="any"
              ></b-form-input>
              <p
                class="m-0 text-error"
                v-if="$v.data.promocodeDiscount.$each[0].discountValue.$error"
              >
                Please input value
              </p>
            </b-col>
            <b-col cols="6">
              <InputSelect
                v-model="data.promocodeDiscount[0].discountType"
                :options="optionAll.promocodeDiscountType"
                id="dicountType"
                title="Discount Type"
                valueField="id"
                textField="name"
                isRequired
                :isValidate="
                  $v.data.promocodeDiscount.$each[0].discountType.$error
                "
                :v="$v.data.promocodeDiscount.$each[0].discountType"
              />
            </b-col>
          </b-row>
        </div>

        <div v-show="selectType.id >= 3">
          <b-form-group label="การวนซ้ำของแถม" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="isLimitLoob"
              :options="optionLimiloob"
              :aria-describedby="ariaDescribedby"
              name="radios-discount"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <div v-if="isLimitLoob">
          <b-row>
            <b-col cols="6">
              <InputText
                v-model="data.promocode.limitLoop"
                textFloat="Limit loop"
                placeholder="Limit loop"
                type="Number"
                name="numberLimitLoob"
                isRequired
                :isValidate="$v.data.promocode.limitLoop.$error"
                :v="$v.data.promocode.limitLoop"
              />
            </b-col>
            <b-col cols="6"></b-col>
          </b-row>
        </div>

        <div v-if="selectType.id >= 2">
          <div class="header-table">
            <strong class="m-0">Discount Product list</strong>
            <b-button class="btn-filter" @click="openModalDiscount">
              Add
            </b-button>
          </div>
          <TableDiscount
            :items="arrDiscount"
            :fields="fieldsDiscount"
            :isBusy="isBusyProduct"
            :rows="rowsProduct"
            :filter="filterProduct"
            :pageOptions="pageOptionsProduct"
            @removeItemDiscount="removeItemDiscount"
            @editItemDiscount="editItemDiscount"
          />
          <p class="m-0 text-error" v-if="$v.data.promocodeDiscount.$error">
            Please select product
          </p>
        </div>
      </div>

      <!-- modal -->
      <ModalCondition
        ref="modalConditionEdit"
        :idNew="data.promocodeCondition.length"
        :eventId="data.promocode.eventId"
        :options="optionAll.promocodeConditionType"
        :eventIdNew="eventIdNew"
        :isLoopCondition="isLoopCondition"
        :editDataCondition="editDataCondition"
        :isEdit="isEdit"
        @addAllProduct="addAllProduct"
      />

      <ModalDiscount
        ref="modalDiscountEdit"
        :idNew="data.promocodeCondition.length"
        :eventId="data.promocode.eventId"
        :options="optionAll.promocodeDiscountType"
        :eventIdNew="eventIdNew"
        :isDicount="isDicount"
        :selectType="selectType"
        :editDataDiscount="editDataDiscount"
        @addAllProductDiscount="addAllProductDiscount"
      />
    </div>
    <ModalAlertText :textModal="textModal" :arrModal="[]" />
    <ModalTable />

    <div class="footer-promocode-detail">
      <b-button
        class="btn-filter gold"
        :disabled="isLoading"
        @click="savePromocodeData"
      >
        <div class="d-flex justify-content-center align-items-center">
          {{ isEdit ? "Update" : "Create" }}
          <b-spinner
            v-if="isLoading"
            class="align-middle ml-2"
            small
          ></b-spinner>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import TableCondition from "./component/table/TableCondition.vue";
import TableDiscount from "./component/table/TableDiscount.vue";

import ModalCondition from "./component/modal/ModalCondition.vue";
import ModalDiscount from "./component/modal/ModalDiscount.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ModalTable from "./component/modal/ModalTable.vue";

import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    TableCondition,
    TableDiscount,
    ModalCondition,
    ModalDiscount,
    ModalAlertText,
    ModalTable,
  },
  data() {
    return {
      isEdit: false,

      data: {
        promocode: {
          id: 0,
          name: null,
          prefix: null,
          quantity: 0,
          startDate: "",
          endDate: "",
          isActive: 1,
          isDeleted: 0,
          isFirstPurchaseOnly: 0,
          serialGenarate: false,
          userGroupId: 0,
          maxUsedPerPerson: 0,
          createdBy: "",
          createdTime: "",
          updatedBy: "",
          updatedTime: "",
          isApplyAllBasicCondition: false,
          promocodeTypeId: 0,
          limitLoop: 0,
          effectiveStartDate: null,
          effectiveEndDate: null,
          eventId: 0,
        },
        promocodeCondition: [],
        promocodeDiscount: [],
        promocodeEffectiveTime: [],
      },

      eventIdNew: null,
      isLoopCondition: false,
      isDicount: false,
      selectType: "",
      isLimitLoob: false,

      // clone quantity
      cloneQuantity: 0,

      // edit data
      editDataCondition: {},
      editDataDiscount: {},

      //   options
      optionEventId: [
        {
          value: 0,
          text: "Promocode ทั้งเว็ป",
        },
        {
          value: 1,
          text: "Promocode เฉพาะ event",
        },
      ],
      optionCodeType: [
        {
          value: false,
          text: "Fix code",
        },
        {
          value: true,
          text: "Auto genarate",
        },
      ],
      optionsCondition: [
        {
          value: false,
          text: "ไม่มีเงื่อนไขพื้นฐาน",
        },
        {
          value: true,
          text: "ใช้เงื่อนไขพื้นฐาน",
        },
      ],
      optionsMatchType: [
        {
          value: true,
          text: "Match all",
        },
        {
          value: false,
          text: "Match any",
        },
      ],
      optionLimiloob: [
        {
          value: false,
          text: "ไม่จำกัด",
        },
        {
          value: true,
          text: "จำกัด",
        },
      ],

      // ? option all
      optionAll: null,

      //   state table false
      // itemsProduct: [],
      rowsProduct: 0,
      filterProduct: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      fieldsArrFalse: [
        {
          key: "id",
          label: "ID",
          class: "w-50px text-nowrap",
        },
        {
          key: "conditionTypeName",
          label: "Type",
          class: "w-50px text-nowrap",
        },
        {
          key: "conditionName",
          label: "Product",
          class: "w-100px text-nowrap",
        },
        {
          key: "conditionValue",
          label: "Value",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      fieldsArrTrue: [
        {
          key: "id",
          label: "ID",
          class: "w-50px text-nowrap",
        },
        {
          key: "conditionTypeName",
          label: "Type",
          class: "w-50px text-nowrap",
        },
        {
          key: "conditionName",
          label: "Product",
          class: "w-100px text-nowrap",
        },
        {
          key: "conditionValue",
          label: "Value",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusyProduct: false,
      pageOptionsProduct: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      fieldsDiscount: [
        {
          key: "id",
          label: "ID",
          class: "w-50px text-nowrap",
        },
        {
          key: "discountTypeName",
          label: "Type",
          class: "w-50px text-nowrap",
        },
        {
          key: "discountName",
          label: "Product",
          class: "w-100px text-nowrap",
        },
        {
          key: "discountValue",
          label: "Value",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],

      // fieldsDiscountTrue: [
      //   {
      //     key: "id",
      //     label: "ID",
      //     class: "w-50px text-nowrap",
      //   },
      //   {
      //     key: "discountTypeName",
      //     label: "Type",
      //     class: "w-50px text-nowrap",
      //   },
      //   {
      //     key: "discountName",
      //     label: "Product",
      //     class: "w-100px text-nowrap",
      //   },
      //   {
      //     key: "discountValue",
      //     label: "Value",
      //     class: "w-50px text-nowrap",
      //   },
      //   { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      // ],

      // text modal
      textModal: "",

      // state toggle
      isLoading: false,
      errorCondition: false,
      errorQuantity: false,
      IsValidate: false,
    };
  },
  validations() {
    return {
      data: {
        promocode: {
          name: { required },
          prefix: { required },
          quantity: {
            required,
            minValue: minValue(this.data.promocode.serialGenarate ? 1 : 0),
          },
          // startDate: { required },
          // endDate: { required },
          limitLoop: {
            required: requiredIf(() => {
              return this.isLimitLoob == true && this.selectType.id >= 3;
            }),
            minValue: minValue(
              this.isLimitLoob == true && this.selectType.id >= 3 ? 1 : 0
            ),
          },
        },
        promocodeCondition: {
          required: requiredIf(() => {
            return this.data.promocode.isUseBasicCondition == true;
          }),
        },
        promocodeDiscount: {
          required: requiredIf(() => {
            return this.selectType.id >= 2;
          }),
          $each: {
            discountType: {
              required: requiredIf(() => {
                return this.selectType.id == 1;
              }),
            },
            discountValue: {
              required: requiredIf(() => {
                return this.selectType.id == 1;
              }),
            },
          },
        },
      },
      eventIdNew: {
        required: requiredIf(() => {
          return this.data.promocode.eventId == 1;
        }),
      },
    };
  },

  computed: {
    arrConditionFalse() {
      let finalArr = this.data.promocodeCondition.filter(
        (x) => x.isLoopCondition == false && x.isDelete == 0
      );
      return finalArr;
    },
    arrConditionTrue() {
      let finalArr = this.data.promocodeCondition.filter(
        (x) => x.isLoopCondition == true && x.isDelete == 0
      );
      return finalArr;
    },
    arrDiscount() {
      let finalArr = this.data.promocodeDiscount.filter((x) => x.isDelete == 0);
      return finalArr;
    },
  },
  mounted() {
    this.getPromocodeDetail();
  },
  methods: {
    async getOptionPromocodeDetail() {
      const getData = await this.$services.master.getOptionPromocodeDetail();

      if (getData.result == 1) {
        this.selectType = getData.detail.promocodeType.find(
          (x) => x.id == this.$route.query.type
        );

        for (let key in getData.detail) {
          if (Object.hasOwnProperty.call(getData.detail, key)) {
            let x = getData.detail[key];
            if (key != "promocodeType") {
              x.unshift({ id: null, name: "กรุณาเลือก", disabled: true });
            }
          }
        }

        this.optionAll = getData.detail;
      }
    },

    async getPromocodeDetail() {
      const getData = await this.$services.promocode.getPromocodeDetail(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.data = getData.detail;

        // create
        if (getData.detail.promocodeDiscount.length == 0) {
          if (this.$route.query.type == 1) {
            this.data.promocodeDiscount = [
              {
                id: 0,
                discountType: null,
                discountValue: null,
                discountProductGroupId: 0,
                isApplyDiscountUsed: true,
                promocodeId: 0,
                isLoopDiscount: true,
                isDelete: 0,
                promocodeProductGroup: null,
              },
            ];
          }
        } else {
          // edit
          this.isEdit = true;

          this.data.promocodeDiscount = this.data.promocodeDiscount.map((x) => {
            x.isDelete = 0;
            return x;
          });
        }

        // edit condition

        if (getData.detail.promocodeCondition.length > 0) {
          this.data.promocodeCondition = this.data.promocodeCondition.map(
            (x) => {
              x.isDelete = 0;
              return x;
            }
          );
        }

        this.data.promocodeEffectiveTime = [
          {
            id: 0,
            promocodeId: 0,
            roundTimeId: 0,
            isAllRoundTime: true,
          },
        ];

        // filter option type 3 and 4
        if (this.$route.query.type >= 3) {
          this.optionEventId = this.optionEventId.map((x) => {
            if (x.value == 0) {
              x.disabled = true;
            }
            return x;
          });
        }
        // if event id > 0

        this.eventIdNew =
          getData.detail.promocode.eventId == 0
            ? null
            : getData.detail.promocode.eventId;

        if (getData.detail.promocode.eventId != 0) {
          this.data.promocode.eventId = 1;
        }

        // all type
        if (this.$route.params.id != 0) {
          this.optionCodeType = this.optionCodeType.map((x) => {
            x.disabled = true;
            return x;
          });
        }

        // cloneQuantity when edit
        if (
          this.$route.params.id != 0 &&
          getData.detail.promocode.serialGenarate
        ) {
          this.cloneQuantity = getData.detail.promocode.quantity;
        }

        // isLimitLoob
        if (getData.detail.promocode.limitLoop != 0) {
          this.isLimitLoob = true;
        }
      }
      this.getOptionPromocodeDetail();
    },

    async savePromocodeData() {
      this.$v.data.$touch();
      this.$v.eventIdNew.$touch();
      const getError = document.getElementById("special-error");

      this.errorQuantity = false;
      this.IsValidate = true;

      // ! ALL VALIDATE
      if (this.$v.data.$error || this.$v.eventIdNew.$error) {
        return;
      }

      // ! condition auto gen only
      if (
        this.cloneQuantity > this.data.promocode.quantity &&
        this.data.promocode.serialGenarate
      ) {
        this.errorQuantity = true;
        return;
      }

      if (getError) {
        return;
      }

      // ? END

      // ! FADE CONVERT DATA

      // add promocodeTypeId
      this.data.promocode.promocodeTypeId = this.selectType.id;

      // convert event id
      if (this.data.promocode.eventId == 1) {
        this.data.promocode.eventId = this.eventIdNew;
      }

      // convert limiloob
      if (this.isLimitLoob == false) {
        this.data.promocode.limitLoop = 0;
      }

      // convert all date to null when create
      if (this.$route.params.id == 0) {
        this.data.promocode.createdTime = null;
        this.data.promocode.updatedTime = null;
      }

      this.data.promocode.startDate = this.data.promocode.startDate
        ? this.data.promocode.startDate
        : null;
      this.data.promocode.endDate = this.data.promocode.endDate
        ? this.data.promocode.endDate
        : null;

      this.data.promocode.effectiveStartDate = this.data.promocode
        .effectiveStartDate
        ? this.data.promocode.effectiveStartDate
        : null;
      this.data.promocode.effectiveEndDate = this.data.promocode
        .effectiveEndDate
        ? this.data.promocode.effectiveEndDate
        : null;

      // convert create by and update by

      this.data.promocode.createdBy = this.data.promocode.createdBy
        ? this.data.promocode.createdBy
        : "";
      this.data.promocode.updatedBy = this.data.promocode.updatedBy
        ? this.data.promocode.updatedBy
        : "";

      // map promocode condtiion

      this.data.promocodeCondition = this.data.promocodeCondition.map((x) => {
        x.id = x.id.toString();
        if (x.id.includes("new")) {
          x.id = 0;
        }

        if (x.promocodeProductGroup.listItem.length == 0) {
          x.promocodeProductGroup = null;
        }

        return x;
      });

      // map promocode discount

      if (this.selectType.id != 1) {
        this.data.promocodeDiscount = this.data.promocodeDiscount.map((x) => {
          x.id = x.id.toString();
          if (x.id.includes("new")) {
            x.id = 0;
          }

          if (x.promocodeProductGroup.listItem.length == 0) {
            x.promocodeProductGroup = null;
          }
          return x;
        });
      }

      this.isLoading = true;

      const save = await this.$services.promocode.savePromocodeData(
        this.$route.params.id,
        this.data
      );

      if (save.result == 1) {
        this.$router.push("/promocode");
      } else {
        this.openModalAlertText(save.detail.message);
      }

      this.isLoading = false;
    },

    changeEventIdNew() {
      this.data.promocodeCondition = [];
      if (this.selectType.id != 1) {
        this.data.promocodeDiscount = [];
      }
    },

    clearDataCondition(val) {
      if (!val) {
        this.data.promocodeCondition = [];
      }
    },

    addAllProduct(obj) {
      const isDuplicate = this.data.promocodeCondition.some(
        (x) => x.id == obj.id
      );

      if (!isDuplicate) {
        this.data.promocodeCondition.push(obj);
      }
    },

    addAllProductDiscount(obj) {
      const isDuplicate = this.data.promocodeDiscount.some(
        (x) => x.id == obj.id
      );

      if (!isDuplicate) {
        this.data.promocodeDiscount.push(obj);
      } else {
        this.data.promocodeDiscount = this.data.promocodeDiscount.map((x) => {
          if (x.id == obj.id) {
            x = obj;
          }
          return x;
        });
      }
    },

    openModalCondition(type) {
      this.isLoopCondition = type;
      this.errorCondition = false;

      if (this.data.promocode.eventId == 1) {
        if (this.eventIdNew) {
          this.$bvModal.show("modal-basic-condition");
        } else {
          let find = document.getElementById("newEvent").scrollHeight;

          window.scrollTo({
            top: find,
            left: 0,
            behavior: "smooth",
          });

          this.errorCondition = true;
        }
      } else {
        this.$bvModal.show("modal-basic-condition");
      }
    },

    opemModalTable() {
      this.$bvModal.show("modal-table");
    },

    openModalDiscount() {
      if (this.selectType.id == 2) {
        this.isDicount = false;
      } else {
        this.isDicount = true;
      }

      this.$bvModal.show("modal-discount");
    },

    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },

    editItem(obj, type) {
      this.editDataCondition = obj;

      this.openModalCondition(type);

      setTimeout(() => {
        this.$refs.modalConditionEdit.convertDataEdit();
      }, 500);
    },

    editItemDiscount(obj) {
      this.editDataDiscount = obj;
      this.openModalDiscount();

      setTimeout(() => {
        this.$refs.modalDiscountEdit.convertDataEdit();
      }, 500);
    },

    removeItem(obj) {
      let { id } = obj;
      let conditionId = typeof id == "string" ? true : false;

      if (conditionId) {
        this.data.promocodeCondition = this.data.promocodeCondition.filter(
          (x) => {
            return x.id != obj.id;
          }
        );
      } else {
        this.data.promocodeCondition = this.data.promocodeCondition.map((x) => {
          if (typeof x.id == "number" && x.id == id) {
            x.isDelete = 1;
          }
          return x;
        });
      }
    },
    removeItemDiscount(obj) {
      let { id } = obj;
      let conditionId = typeof id == "string" ? true : false;

      if (conditionId) {
        this.data.promocodeDiscount = this.data.promocodeDiscount.filter(
          (x) => {
            return x.id != obj.id;
          }
        );
      } else {
        this.data.promocodeDiscount = this.data.promocodeDiscount.map((x) => {
          if (typeof x.id == "number" && x.id == id) {
            x.isDelete = 1;
          }
          return x;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.promocode-detail {
  //   background: white;
  .text-type {
    text-align: right;
    margin: 0px;
  }

  .header-box {
    padding: 15px;
    background: var(--secondary-color);
    color: var(--font-color);
  }

  .body {
    margin-top: 10px;
    background: white;

    .detail {
      padding: 15px;

      .header-table {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }

  .footer-promocode-detail {
    margin-top: 10px;
    text-align: right;
  }
}

// ! override
.input-container {
  margin-bottom: 10px;
}

.btn-filter {
  width: 100%;
  max-width: 100px;

  &.gold {
    background: var(--secondary-color);
    color: white !important;
    border-color: transparent !important;
  }

  &.modal-table {
    height: 35px;
    max-width: fit-content;
    // background: var(--secondary-color);
    // color: white !important;
    // border-color: transparent !important;
  }
}
</style>
