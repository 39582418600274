<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <!-- quantity  -->
          <template #cell(quantity)="{ item, index }">
            <InputText
              v-if="v.data.promocodeProductGroup.listItem.$each[index]"
              class="m-0"
              v-model="item.quantity"
              textFloat=""
              placeholder="0"
              type="number"
              name="quantity"
              :isValidate="
                v.data.promocodeProductGroup.listItem.$each[index].quantity
                  .$error
              "
              :v="v.data.promocodeProductGroup.listItem.$each[index].quantity"
            />
          </template>

          <template v-slot:cell(actions)="{ item }">
            <font-awesome-icon
              class="pointer"
              icon="trash-alt"
              @click="removeItem(item)"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    /> -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    v: {
      type: Object,
      default: {},
      required: false,
    },
  },
  methods: {
    removeItem(obj) {
      this.$emit("removeItem", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
